<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->

      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />

    <v-card elevation="0">
      <v-card-title>Pending Orange Reports</v-card-title>
      <v-card-text>
        <v-progress-linear
          :active="orangeReportsLoading"
          :indeterminate="orangeReportsLoading"
          absolute
          bottom
        ></v-progress-linear>
        <div v-if="!orangeReportsLoading">
          <!-- Start Recent Orange Reports section -->
          <v-row v-if="sortedOrangeReports.length > 0">
            <v-col
              md="4"
              sm="6"
              cols="12"
              v-for="(item, i) in sortedOrangeReports"
              :key="'o' + i"
            >
              <div>
                <p>
                  {{ item.title }}
                  <router-link
                    class="green--text"
                    :to="
                      `/customers/${customerId}/orange-reports/${item.id}/edit`
                    "
                    >{{
                      permissions.orangeReport &&
                      permissions.orangeReport.canApprove
                        ? "Edit / Approve "
                        : "Edit "
                    }}
                    Orange Report</router-link
                  >
                </p>
                <router-link
                  class="green--text"
                  :to="
                    `/customers/${customerId}/orange-reports/${item.id}/edit`
                  "
                >
                  <v-img
                    height="200"
                    width="300"
                    :src="reportPicture(item)"
                  ></v-img>
                </router-link>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="text-center">
              <div class="text-subtitle-1 text-center">
                No Pending Orange Reports
              </div>
              <router-link
                v-if="
                  permissions.orangeReport && permissions.orangeReport.canCreate
                "
                :to="`/customers/${customerId}/orange-reports/new`"
              >
                <v-btn class="orange-bg white--text" small
                  >Create Orange Report</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
        </div>
        <!-- End Recent Orange Reports section -->
      </v-card-text>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    orangeReports: [],
    sortedOrangeReports: [],
    permissions: {},
    orangeReportsLoading: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    // options: async function() {
    //   this.isLoading = true;
    //   if (this.previousPage < this.options.page && this.options.page !== 1) {
    //     this.setStartingAfter();
    //   } else if (
    //     this.previousPage > this.options.page &&
    //     this.options.page !== 1
    //   ) {
    //     this.setEndingBefore();
    //   } else {
    //     this.startingAfter = "";
    //     this.endingBefore = "";
    //   }
    //   this.isLoading = false;
    // }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
    this.getRecentOrangeReports();
  },
  async created() {
    // let self = this;
    // self.loading = true;
    // Promise.all([

    //   // await this.getRecipients()
    // ]).then(function() {
    //   self.loading = false;
    // });
    permissionsHelper.getPermissions().then(this.getPermissions);
    await this.getComunityInfo();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loading = false;
        });
    },
    async getRecentOrangeReports() {
      this.orangeReportsLoading = true;
      let reportsUrl = `${API_CUSTOMERS}/${this.customerId}/orangeReports`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.orangeReports = response.data.$values;

            this.sortedOrangeReports = this.getSortedOrangeReports();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.orangeReportsLoading = false;
        });
    },

    getSortedOrangeReports() {
      let items = [];
      // if (!this.orangeReports || this.orangeReports.length === 0) return items;
      //.slice();
      items = this.orangeReports.filter(el => !el.isApproved);
      // for (let i = 0; i < this.orangeReports.length; i++) {
      //   if (!this.orangeReports[i].isApproved)
      //     items.push(this.orangeReports[i]);
      // }

      items.sort((a, b) => {
        return new Date(b.serviceDate) - new Date(a.serviceDate);
      });
      return items;
    },

    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Pending Orange Reports" }
      ];
    },

    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Pending Orange Reports" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    reportPicture(report) {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";
      if (!report) return defaultPicture;

      if (report.photos.$values.length > 0) {
        let imagesToShow = report.photos.$values.filter(i => i.showInReport);
        if (imagesToShow && imagesToShow.length > 0)
          return imagesToShow[0].fileUrl;
        return report.photos.$values[0].fileUrl;
      }

      return defaultPicture;
    }
  },
  computed: {}
};
</script>
